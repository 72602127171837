// import GoogleMapReact, {Map, GoogleApiWrapper, Marker} from 'google-map-react';
// import React, {Component} from 'react'
//
//
// export default class MapContainer extends Component {
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             stores: [{lat: 47.49855629475769, lng: -122.14184416996333},
//                 {latitude: 47.359423, longitude: -122.021071},
//                 {latitude: 47.2052192687988, longitude: -121.988426208496},
//                 {latitude: 47.6307081, longitude: -122.1434325},
//                 {latitude: 47.3084488, longitude: -122.2140121},
//                 {latitude: 47.5524695, longitude: -122.0425407}]
//         }
//     }
//
//     displayMarkers = () => {
//         return this.state.stores.map((store, index) => {
//             return <Marker key={index} id={index} position={{
//                 lat: store.latitude,
//                 lng: store.longitude
//             }}
//                            onClick={() => console.log("You clicked me!")}/>
//         })
//     }
//
//     render() {
//         return (
//             <GoogleMapReact
//                 //bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
//                 defaultCenter={this.props.center}
//                 defaultZoom={this.props.zoom}
//             >
//                 {this.displayMarkers()}
//                 {/*<AnyReactComponent*/}
//                     {/*lat={59.955413}*/}
//                     {/*lng={30.337844}*/}
//                     {/*text="My Marker"*/}
//                 {/*/>*/}
//             </GoogleMapReact>
//         );
//     }
// }

import _ from "lodash"
import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import Request from '../../request'

const AnyReactComponent = ({text}) => <div>{text}</div>;

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 28.0086486,
            lng: 73.33631516
        },
        zoom: 15
    };

    constructor(props) {
        super(props);

        this.state = {
            mapData: [],
        };
    }

    async componentDidMount() {
        let array = []
        let {data} = await Request.getAllMachines()
        _.each(data, (item) => {
            let a = {}
            a.lat = item.latitude
            a.long = item.longitude
            a.name = item.name

            array.push(a)
        })
        this.setState({mapData: array})
        console.log(this.state.mapData)
    }

    render() {
        {
            console.log(this.state.mapData)
        }
        const Marker = ({text}) => {
            return (
                <div><img style={{height: 30}} src={'/map.png'} alt={text} title={text}></img>{text}</div>
            );
        }
        return (
            <div style={{height: '100vh', width: '100%'}}>
                <GoogleMapReact
                    //  bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    {
                        this.state && this.state.mapData.map((each) =>
                            <Marker
                                lat={each.lat}
                                lng={each.long}
                                text={each.name}
                            />)
                    }


                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;
