import axios from 'axios'

import {apiUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
}

class Request {

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
            }
        } catch (e) {
        }
    }

    constructor() {

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllUser(data) {
        return new Promise((next) => {
            authAxios
                .get('/user', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addUser(data) {
        return new Promise((next) => {
            authAxios
                .post('/user', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addMachine(data) {
        return new Promise((next) => {
            authAxios
                .post('/machine', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getUserById(data) {
        return new Promise(next => {
            authAxios
                .get('/user/' + data.id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateUser(data) {
        return new Promise(next => {
            authAxios
                .put('/user/' + data.id_ai, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addMachineGroup(data) {
        return new Promise((next) => {
            authAxios
                .post('/machineGroup', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    allMachines(data) {
        return new Promise((next) => {
            authAxios
                .get('/allMachines?count=1000000', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    allGroups(data) {
        return new Promise((next) => {
            authAxios
                .get('/allmachineGroups?count=1000000', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    allRateCharts(data) {
        return new Promise((next) => {
            authAxios
                .get('/allRateCharts?count=1000000', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllMachines(data) {
        return new Promise((next) => {

            if (!data) {
                data = {
                    count: 10000000
                }
            }
            data.count = 10000000
            authAxios
                .post('/machineList', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getMachineById(data) {
        return new Promise(next => {
            authAxios
                .get('/machine/' + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getMachineDetailsById(data) {
        return new Promise(next => {
            authAxios
                .get('/machine/data/' + data.id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    updateMachine(data) {
        return new Promise(next => {
            authAxios
                .put('/machine/' + data.id_ai, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteUser(data) {
        return new Promise(next => {
            authAxios
                .delete(`/user/` + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteMachine(data) {
        return new Promise(next => {
            authAxios
                .delete(`/machine/` + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getMachineGroupById(data) {
        return new Promise(next => {
            authAxios
                .get('/machineGroup/' + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteMachineGroup(data) {
        return new Promise(next => {
            authAxios
                .delete(`/machineGroup/` + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllMachineGroups(data) {
        return new Promise((next) => {
            authAxios
                .get('/machineGroup', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addFarmer(data) {
        return new Promise((next) => {
            authAxios
                .post('/farmer', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllFarmers(data) {

        return new Promise((next) => {
            if (!data) {
                data = {}
            }
            data.count = 10000000
            authAxios
                .post('/farmerList', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    deleteFarmer(data) {
        return new Promise(next => {
            authAxios
                .delete(`/farmer/` + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateFarmer(data) {
        return new Promise(next => {
            authAxios
                .put('/farmer/' + data.id_ai, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getFarmerById(data) {
        return new Promise(next => {
            authAxios
                .get('/farmer/' + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addRateChart(data) {
        return new Promise((next) => {
            authAxios
                .post('/rateChart', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllRateCharts(data) {
        return new Promise((next) => {
            authAxios
                .get('/rateChart', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    deleteRateChart(data) {
        return new Promise(next => {
            authAxios
                .delete(`/rateChart/` + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateRateChart(data) {
        return new Promise(next => {
            authAxios
                .put('/rateChart/' + data.id_ai, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getRateChartById(data) {
        return new Promise(next => {
            authAxios
                .get('/rateChart/' + data.id_ai, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllDashboardDetails(data) {
        return new Promise((next) => {
            authAxios
                .get('/dashboard', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getMilkCollected() {
        return new Promise((next) => {
            authAxios
                .get('/milkWeight', getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllShifts(data) {
        return new Promise((next) => {
            authAxios
                .get('/allShifts', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    viewFile(data) {
        return new Promise((next) => {
            authAxios
                .get('/viewcsvFile', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    updateMachineGroup(data) {
        return new Promise(next => {
            authAxios
                .put('/machineGroup/' + data.id_ai, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }
}


export default new Request()
