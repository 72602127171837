import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

@Form.create()
class AddFarmer extends PureComponent {

    state = {
        key: Math.random() * 1000
    }
    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values.id_ai = this.state.id
                    x = await Request.updateFarmer(values)
                } else {
                    // values.machineId = this.state && this.state.singleMachine.id
                    x = await Request.addFarmer(values)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: this.state.id ? 'Farmer updated successfully' : 'Farmer added successfully'
                    })

                    if (!this.state.id) {
                        this.props.form.setFieldsValue({})
                        form.resetFields()
                    }
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            options: []
        }

    }

    async componentDidMount() {

        let allmachines = await Request.allMachines()
        this.setState({
            allMachines: allmachines.data
        })

        let searchParams = new URLSearchParams(this.props.search)

        let farmerId = searchParams.get('id')
        if (farmerId) {
            Request.getFarmerById({id_ai: farmerId}).then(
                ({data, error, message}) => {
                    if (!error) {
                        this.setState({
                            id: data.id_ai,
                            key: Math.random() & 100
                        })
                        this.props.form.setFieldsValue({
                            id: data && data.id,
                            name: data && data.name,
                            mobileNo: data && data.mobileNo,
                            machineId: data && data.machineId

                        })

                    } else {
                        notification.error({
                            message: 'Error Getting Data',
                            description: message
                        })
                    }
                }
            )
        }
    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, options} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }
        let inputTypes = {
            fields: [
                {
                    label: 'Farmer id',
                    key: 'id',
                    required: true
                },
                {
                    label: 'Name',
                    key: 'name',
                    required: true
                },
                {
                    label: 'Mobile No',
                    key: 'mobileNo',
                    required: true
                },
                {
                    label: 'machineId',
                    type: 'select',
                    key: 'machineId',
                    keyAccessor: x => x.id,
                    valueAccessor: x => (`${x.name} (${x.id})`),
                    options: this.state.allMachines || [],
                    onChange: machine => {
                        let singleMachine = _.find(this.state.allMachines, (item) => {
                            return item.id == machine
                        })
                        this.setState({singleMachine})
                        this.props.form.setFieldsValue({
                            machineId: machine
                        })

                    }

                },


            ]
        }

        return (

            <PageHeaderWrapper title={this.state && this.state.id ?
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('farmers.allFarmers')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>Update Farmer</p></div> : 'Add Farmer'}>

                <Card bordered={true}>
                    <Form
                        key={this.state.key}
                        onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFarmer)
