import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../request'
import Color from 'color'
import _ from 'lodash'
import moment from 'moment'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../routes'

class viewcsv extends Component {


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            rateChartFileData: ''
        }

    }

    componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)
        let rateChartId = searchParams.get('id')

        Request.viewFile({id_ai: rateChartId}).then(({data}) => {

            this.setState({rateChartFileData: data})

        })
    }

    render() {
        const {dispatch} = this.props
        const {rateChartFileData: RCF} = this.state

        const RCFKeys = (RCF && RCF[0]) ? Object.keys(RCF[0]) : []

        return (
            <PageHeaderWrapper title={
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}} loading={this.props.loading}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('rateChart.allRateCharts')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>CSV FILE</p></div>}>

                <Card bordered={true}>

                    <table cellPadding={10} cellSpacing={10} border="1">
                        <thead>
                        <tr>
                            {RCFKeys.map((x, y) => {
                                return <td key={y}>{x.toUpperCase()}</td>
                            })}
                        </tr>
                        </thead>
                        {RCF && RCF.map((v, k) => {
                            return <tr key={k}>
                                {(Object.keys(v)).map((p, q) => {
                                    return <td key={p}>
                                        {v[p]}
                                    </td>
                                })}
                            </tr>
                        })}
                    </table>

                </Card>

            </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(viewcsv)
