import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Row,
    Col,
    Spin,
    Empty,
    Checkbox,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import styles from './styles.less'

import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddUser extends PureComponent {

    state = {
        key: Math.random() * 1000
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values.id_ai = this.state.id
                    x = await Request.updateUser(values)
                } else {
                    x = await Request.addUser(values)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: this.state.id ? 'User updated successfully' : 'User added successfully'
                    })

                    if (!this.state.id) {
                        this.props.form.setFieldsValue({})
                        form.resetFields()
                    }
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    loadMachineGroup = (userType) => {

        if (userType == 'groupManager') {
            this.setState({allMachinesLoading: true})

            return new Promise(async resolve => {
                let data = await Request.allGroups()
                this.setState({allGroups: data.data})

                this.setState({
                    locationSet: true,
                    allMachinesLoading: false,
                })
            })
        } else {
            this.setState({
                locationSet: false
            })

        }

    }

    constructor(props) {
        super(props)
        this.state = {
            options: []
        }

    }

    async componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)

        let userId = searchParams.get('id')
        if (userId) {
            Request.getUserById({id: userId}).then(
                ({data, error, message}) => {
                    if (!error) {

                        this.setState({
                            id: data.id_ai,
                            key: Math.random() & 100
                        })
                        this.loadMachineGroup(data.userType)
                        this.props.form.setFieldsValue({
                            name: data && data.name,
                            username: data && data.username,
                            email: data && data.email,
                            userType: data && data.userType,
                        })


                        setTimeout(() => {
                            this.props.form.setFieldsValue({
                                machineGroups: data.machineGroups.map(x => parseInt(x))
                            })
                        }, 300)

                    } else {
                        notification.error({
                            message: 'Error Getting Data',
                            description: message
                        })
                    }
                }
            )
        }
    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, options, allGroups, allMachinesLoading} = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }
        let inputTypes = {
            fields: [
                {
                    label: 'Full Name',
                    key: 'name',
                    required: true
                },
                {
                    label: 'Username',
                    key: 'username',
                    required: true,
                    disabled: this.state && this.state.id ? true : false
                },
                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true,
                    hidden: this.state && this.state.id ? true : false

                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true,

                },
                {
                    label: 'User Type',
                    key: 'userType',
                    required: true,
                    disabled: false,
                    allowClear: true,
                    type: 'select',
                    options: [
                        {
                            _id: 'admin',
                            display: 'Admin'
                        },
                        {
                            _id: 'manager',
                            display: 'Manager'
                        },
                        {
                            _id: 'groupManager',
                            display: 'Group Manager'
                        }
                    ],
                    onChange: userType => {
                        setFieldsValue({userType: userType})
                        console.log(userType, "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
                        this.loadMachineGroup(userType)

                    }

                }
            ]
        }

        if (!!this.state.id) {
            // inputTypes.fields = _.filter()
        }

        return (
            <PageHeaderWrapper title={this.state && this.state.id ?
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('users.allUsers')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>Update User</p></div> : 'Add New User'}>

                <Card bordered={true}>
                    <Form
                        {...formItemLayout}
                        key={this.state.key}
                        onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>
                        {this.state.locationSet && <Form.Item label="Machines in Group">
                            {getFieldDecorator('machineGroups', {})(
                                <Checkbox.Group className={styles.checkbox}
                                                options={(() => {
                                                    return allGroups ? allGroups.map(x => ({
                                                        label: `${x.name} (${x.id_ai})`,
                                                        value: x.id_ai
                                                    })) : []
                                                })()}
                                />
                            )}
                        </Form.Item>}
                        {/*{this.state.locationSet && <Form.Item*/}
                        {/*label="Machines in Group">*/}
                        {/*{getFieldDecorator('machineIds')(*/}
                        {/*<Checkbox.Group defaultValue={[]} className={styles.checkbox}*/}
                        {/*>*/}
                        {/*{!allMachinesLoading ? <Row>*/}
                        {/*{allGroups ? allGroups.map((val, key) => {*/}
                        {/*return (<Col className={styles.column} key={val.id_ai} span={24}>*/}
                        {/*<Checkbox value={val.id_ai}>{val.name} ({val.id_ai})</Checkbox>*/}
                        {/*</Col>)*/}
                        {/*}) : <Empty/>}*/}
                        {/*</Row> : <Spin/>}*/}
                        {/*</Checkbox.Group>*/}
                        {/*)}*/}
                        {/*</Form.Item>}*/}


                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)
