import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Select,
    Switch,
    Input, Button, Icon, DatePicker
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import moment from 'moment'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'


const {Option} = Select
const {RangePicker} = DatePicker

class AllShifts extends Component {

    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            console.log(params)

            this.setState({
                params
            })

            params.machineId = this.state.machineId

            params.time = this.state && this.state.time
            params.sortOrder = params.sortOrder ? params.sortOrder : 'descend'
            params.sortField = 'time'
            params.syncTime = this.state && this.state.shift

            let data = await Request.getAllShifts({...params})
            this.setState({shiftData: data.data})
            console.log(data)
            resolve(data)
        })
    }

    reload = () => {
        this.table.current.reload()
    }
    chooseRangerPicker = (date, dateString) => {

        this.setState(
            {
                time: {
                    from: dateString[0],
                    to: dateString[1]
                }
            },
            () => {
                this.reload()
            }
        )
        if (dateString[0] == undefined) {
            setTimeout(() => {
                this.reload()
            }, 500)
        }
    }
    getMachineName = (data) => {
        if (this.state && this.state.machineData) {
            let found = _.find(this.state.machineData, (val) => {
                return val.id == data
            })
            if (found) {
                return `${found.name} (${found.id})`
            }
        }
    }

    exportData = async () => {
        console.log(this.state.machineId, "#####################################")
        let params = {}
        params.machineId = this.state.machineId

        params.time = this.state && this.state.time
        params.sortOrder = params.sortOrder ? params.sortOrder : 'descend'
        params.sortField = 'time'
        params.syncTime = this.state && this.state.shift

        let data = await Request.getAllShifts({
            ...params, export: true, count: 1000000
        })
        if (data) {
            window.open(data.url)

        }

    }

    constructor(props) {
        super(props)
        this.state = {
            allMachines: null,
            initialSelectedIds: null,
            machineId: ''
        }
        this.table = React.createRef()

    }

    async componentDidMount() {
        let allmachines = await Request.allMachines()
        this.setState({
            machineData: allmachines.data
        })

    }

    render() {
        const {dispatch} = this.props
        const {machineData, farmerData, shiftData} = this.state

        const columns = [
            {
                title: 'Machine (id)', dataIndex: 'machineId',
                render: (val) => {
                    return this.getMachineName(val)
                }
            },
            {title: 'Customer', dataIndex: 'farmerId', searchTextName: 'farmerId'},
            {title: 'Fat', dataIndex: 'fat'},
            {title: 'CLR', dataIndex: 'clr'},
            {title: 'Milk Type', dataIndex: 'milkType'},
            {title: 'Weight', dataIndex: 'weight'},
            {title: 'Rate', dataIndex: 'rate'},
            {title: 'Amount', dataIndex: 'amount'},
            {
                title: 'Sync Time M/E', dataIndex: 'syncTime',
                render: (val) => {
                    return <div>{val && val.toUpperCase()}</div>
                }
            },
            {
                title: 'Date/Time', dataIndex: 'time', sorter: true, render: (val) => {
                    return moment(val).format('DD MMM YYYY @ hh:MM a')
                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={'All Shifts'}>

                <Card bordered={true}>

                    <div>

                        <Select
                            showSearch
                            style={{width: 200}}
                            placeholder='Select Machine'
                            optionFilterProp="children"
                            value={this.state.machineName}
                            onChange={machine => {
                                let machineFound = machineData && _.find(machineData, (val) => {
                                    return val.name == machine
                                })

                                if (machineFound) {
                                    this.setState(
                                        {machineId: machineFound.id, machineName: machineFound.name},
                                        () => {
                                            this.table.current.reload()
                                        }
                                    )

                                }


                            }}

                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >

                            {machineData && machineData.map((val, idn) => {
                                return (
                                    <Option key={idn} value={val.name}>
                                        {val.name}
                                    </Option>
                                )
                            })}
                        </Select>

                        <RangePicker
                            showTime={{format: 'HH:mm'}}
                            style={{width: '22%', marginLeft: 20}}
                            format="YYYY-MM-DD HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            onChange={this.chooseRangerPicker}
                            onOk={this.onOk}
                        />

                        <Select placeholder="Select Shift" style={{width: 200, marginLeft: '20px'}}
                                onChange={shift => {
                                    console.log(shift)




                                    this.setState(
                                        {shift: shift},
                                        () => {

                                            this.apiRequest({...this.state.params , page : 1 }).then(() => {
                                                this.reload()
                                            })
                                        }
                                    )


                                }}>
                            <Option value="e">Evening</Option>
                            <Option value="m">Morning</Option>
                        </Select>
                        <Button
                            style={{float: 'right', marginBottom: 10}}
                            type="default"
                            onClick={() => {
                                this.exportData()
                            }}
                            loading={this.props.loading}>
                            Download Export Excel
                        </Button>
                    </div>


                    <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest}/>
                </Card>

            </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllShifts)
