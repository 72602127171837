import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon,
    Row, Col

} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../modules/actions'
import Request from '../../request'
import {connect} from 'react-redux'
import {TableComp} from 'sz-react-utils'
import styles from './styles.less'

import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from '../../routes'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class Dashboard extends Component {
    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.count = 10
            params.page = 1


            let data = await Request.getAllDashboardDetails(params)
            this.setState({shiftsData: data.data})
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            allMachines: null,
            initialSelectedIds: null,
            shiftsData: []
        }

    }

    async componentDidMount() {

        let {
            machineGroups,
            machines,
            rateCharts,
            totalMilkWeight,
            data
        } = await Request.getAllDashboardDetails()

        this.setState({
            machineGroups,
            machines,
            rateCharts,
            totalMilkWeight,
            shiftsData: data
        })

    }

    render() {
        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            machineGroups,
            machines,
            rateCharts,
            totalMilkWeight
        } = this.state

        const columns = [
            {
                title: 'Machine Id', dataIndex: 'machineId',
                render: (val) => {
                    return val
                }
            },
            {title: 'Farmer Id', dataIndex: 'farmerId'},
            {title: 'Fat', dataIndex: 'fat'},
            {title: 'CLR', dataIndex: 'clr'},
            {title: 'Weight', dataIndex: 'weight'},
            {title: 'Rate', dataIndex: 'rate'},
            {title: 'Amount', dataIndex: 'amount'},
            {title: 'Milk Type', dataIndex: 'milkType'},
            {
                title: 'Sync Time M/E', dataIndex: 'syncTime',
                render: (val) => {
                    return <div>{val && val.toUpperCase()}</div>
                }
            },

            {
                title: 'Date/Time', dataIndex: 'time', render: (val) => {
                    return moment(val).format('DD MMM YYYY @ hh:MM a')
                }
            }

        ]

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }

        return (
            <PageHeaderWrapper
                title={'Dashboard'}
            >
                <Card bordered={true}>

                    <Row>
                        <Col span={6} style={{justifyContent: 'space-around'}}>
                            <div style={{
                                backgroundColor: '#021c1e',
                                color: 'white',
                                borderRadius: 10,
                                padding: 20,
                                width: '80%',
                                margin: 'auto'
                            }}>
                                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20}}>Total Machines
                                </div>
                                <br/>
                                <div style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: 18
                                }}>{machines}</div>
                            </div>

                        </Col>

                        <Col span={6} style={{justifyContent: 'space-around'}}>

                            <div style={{
                                backgroundColor: '#004445',
                                color: 'white',
                                borderRadius: 10,
                                padding: 20,
                                width: '80%',
                                margin: 'auto'

                            }}>
                                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20}}>Total Groups</div>
                                <br/>
                                <div style={{
                                    textAlign: 'center', fontWeight: 'bold',
                                    fontSize: 18
                                }}>{machineGroups}</div>
                            </div>

                        </Col>
                        <Col span={6} style={{
                            justifyContent: 'space-around'
                        }}>

                            <div style={{
                                backgroundColor: '#2c7873', width: '80%', color: 'white',
                                margin: 'auto',
                                borderRadius: 10, padding: 20
                            }}>
                                < div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20}}>Total Rate Chart
                                </div>
                                <br/>
                                <div style={{
                                    textAlign: 'center', fontWeight: 'bold',
                                    fontSize: 18
                                }}>{rateCharts}</div>
                            </div>

                        </Col>

                        <Col span={6} style={{
                            justifyContent: 'space-around'
                        }}>

                            <div style={{
                                backgroundColor: '#6fb987',
                                margin: 'auto', position: 'relative',
                                color: 'white', width: '80%', borderRadius: 10, padding: 20
                            }}>
                                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20}}>Total Milk
                                </div>
                                <br/>
                                <div style={{
                                    textAlign: 'center', fontWeight: 'bold',
                                    fontSize: 18
                                }}>{totalMilkWeight} Kg
                                </div>

                                <div
                                    className={styles.last24}>in last 24 hours.
                                </div>

                            </div>


                        </Col>
                    </Row>

                </Card>

                <Card bordered={true}>
                    <div style={{
                        borderBottom: '1px solid grey', paddingBottom: 5, fontWeight: 'bold',
                        fontSize: 18
                    }}>
                        Last 10 transactions of milk collection (last 24 hours)
                    </div>
                    <TableComp ref={this.table}
                               columns={columns}
                               dataSource={this.state.shiftsData}
                               pagination={{
                                   showSizeChanger: false,
                                   showQuickJumper: false,
                                   hideOnSinglePage: true
                               }}
                    />

                </Card>

            </PageHeaderWrapper>
        )
    }

}

const
    mapStateToProps = ({global, router}) => ({
        loading: global.buttonLoading,
        categories: global.categories,
        search: router.location.search
    })
const
    mapDispatchToProps = dispatch => {
        return {
            dispatch
        }
    }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

