import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon,
    Empty, Spin,
    Checkbox,
    Row, Col
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from './styles.less'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddRateChart extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                if (values.csvFile && values.csvFile.length) {
                    values.csvFile = _.map(values.csvFile, (item) => {
                        return item.response ? item.response : item
                    })
                }

                if (values.milkType && values.milkType == 'Cow') {
                    values.milkType = 'COW'
                }
                else {
                    values.milkType = 'BUFF'
                }
                values.filePath = values.csvFile[0].url
                values.type = values.type.toLowerCase()

                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values.id_ai = this.state.id
                    values.machineIds = values.machineIds ? values.machineIds : values.groupIds
                    x = await Request.updateRateChart(values)
                } else {
                    x = await Request.addRateChart(values)
                    this.props.form.resetFields()
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    setFormValues = async (slug) => {

    }

    constructor(props) {
        super(props)
        this.state = {
            allMachines: null,
            initialSelectedIds: null,
            allGroups: null,
            chooseType: ''
        }

    }


    async componentDidMount() {

        let allmachines = await Request.getAllMachines()
        let allmachineGroups = await Request.allGroups()
        this.setState({
            machineData: allmachines.data, groupData: allmachineGroups.data
        })

        let searchParams = new URLSearchParams(this.props.search)

        let rateChartId = searchParams.get('id')

        if (rateChartId) {
            Request.getRateChartById({id_ai: rateChartId}).then(
                ({data, error, message}) => {
                    if (!error) {

                        this.setState({
                            id: data.id_ai,
                            key: Math.random() & 100
                        })
                        this.setState({display: true})

                        this.props.form.setFieldsValue({
                            milkType: data && data.milkType,
                            type: data && data.type,
                            name: data && data.name,
                            csvFile: data && data.filePath && [{
                                url: data.filePath,
                                uid: '34543534534',
                                name: data.filePath
                            }],


                        })

                        if (data && data.type == 'machine') {
                            this.setState({chooseType: 'machine'})
                            this.props.form.setFieldsValue({
                                machineIds: data.machineIds && data.machineIds.map(x => parseInt(x))


                            })

                        } else {
                            this.props.form.setFieldsValue({
                                groupIds: data.machineIds && data.machineIds.map(x => parseInt(x))


                            })
                        }


                    } else {
                        notification.error({
                            message: 'Error Getting Data',
                            description: message
                        })
                    }
                }
            )
        }


    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {machineData, groupData} = this.state


        const inputTypes = {
            fields: [
                {label: 'Name', key: 'name', required: true},
                {
                    label: 'Milk Type',
                    key: 'milkType',
                    required: true,
                    type: 'select', options: ['Cow', 'Buffalo'],
                    onChange: v => {
                        this.props.form.setFieldsValue({milkType: v})
                    }

                },
                {
                    label: 'Type',
                    key: 'type',
                    required: true,
                    type: 'select', options: [

                        {
                            _id: 'machine',
                            display: 'Machine'
                        },
                        {
                            _id: 'group',
                            display: 'Group'
                        }
                    ],
                    onChange: v => {
                        this.setState({chooseType: v})
                        this.props.form.setFieldsValue({type: v})
                    }

                },


                {label: 'CSV File', key: 'csvFile', type: 'file', required: true}

            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }


        return (

            <PageHeaderWrapper title={this.state && this.state.id ?
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('rateChart.allRateCharts')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>Update Rate Chart</p></div> : 'Add Rate Chart'}>

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} {...formItemLayout} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes}
                                          getFieldDecorator={getFieldDecorator}/>


                        <Form.Item
                            label={this.state && this.state.chooseType == 'machine' ? 'All Machines' : 'Machines in Group'}>
                            {this.state && this.state.chooseType == 'machine' ? getFieldDecorator('machineIds', {})(
                                <Checkbox.Group className={styles.checkbox}
                                                options={(() => {
                                                    return machineData ? machineData.map(x => ({
                                                        label: `${x.name} (${x.id_ai})`,
                                                        value: x.id_ai
                                                    })) : []
                                                })()}
                                />
                            ) : getFieldDecorator('groupIds', {})(
                                <Checkbox.Group className={styles.checkbox}
                                                options={(() => {
                                                    return groupData ? groupData.map(x => ({
                                                        label: `${x.name} (${x.id_ai})`,
                                                        value: x.id_ai
                                                    })) : []
                                                })()}
                                />
                            )}

                        </Form.Item>


                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRateChart)
