import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../modules/actions'
import Request from '../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'

// inputSchema.fields

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class ChangeKanta extends PureComponent {

    state = {
        key: Math.random() * 1000
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                dispatch(showLoader())

                let x = null
                values.id_ai = this.state.machineId

                x = await Request.updateMachine(values)


                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: 'Kanta updated successfully'
                    })
                    //  form.resetFields()
                    let {data: allMachines} = await Request.getAllMachines()
                    this.setState({allMachines})
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    reload = () => {
        this.table.current.reload()
    }

    constructor(props) {
        super(props)
        this.state = {
            options: [],
            machineId: '',

        }
        this.table = React.createRef()

    }

    async componentDidMount() {

        let {data: allMachines} = await Request.getAllMachines()
        this.setState({allMachines})
        let searchParams = new URLSearchParams(this.props.search)

        let userId = searchParams.get('id')


    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, options} = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }
        let inputTypes = {
            fields: [
                // {
                //     label: 'Machine',
                //     type: 'select',
                //     key: 'id_ai',
                //     keyAccessor: x => x.id_ai,
                //     valueAccessor: x => (`${x.name} (${x.id})`),
                //     options: this.state.allMachines || [],
                //     onChange: machine => {
                //         console.log(machine)
                //         this.setState({id: machine})
                //         let singleMachine = _.find(this.state.allMachines, (item) => {
                //             return item.id_ai == machine
                //         })
                //
                //         this.props.form.setFieldsValue({
                //             id_ai: machine,
                //             kanta: singleMachine && singleMachine.kanta
                //         })
                //     }
                //
                // },
                {
                    label: 'Kanta',
                    key: 'kanta',
                    required: true,
                    hidden: this.state && this.state.allMachines ? false : true,
                    type: 'select',
                    disabled: this.state && this.state.id ? false : true,
                    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                    onChange: kanta => {
                        this.props.form.setFieldsValue({
                            kanta
                        })
                    }


                }
            ]
        }

        if (!!this.state.id) {
            // inputTypes.fields = _.filter()
        }

        return (
            <PageHeaderWrapper
                title={'Change Kanta'}
            >

                <Card bordered={true}>
                    <Form
                        key={this.state.key}
                        onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>
                        <Form.Item>
                            <div style={{display: 'inline-flex', marginLeft: '27%'}}>
                                < p style={{
                                    marginRight: '10px', height: '15px',
                                    marginBottom: '0'
                                }}>Machine:</p>
                                {this.state && this.state.allMachines &&
                                <Select
                                    showSearch
                                    style={{width: 506, marginTop: '5px'}}
                                    placeholder='Select Machine'
                                    optionFilterProp="children"
                                    value={this.state && this.state.machineName}
                                    onChange={machine => {

                                        this.setState({id: machine})
                                        let singleMachine = _.find(this.state.allMachines, (item) => {
                                            return item.id_ai == machine
                                        })
                                        if (singleMachine) {
                                            this.setState(
                                                {machineId: singleMachine.id_ai, machineName: singleMachine.name},
                                                () => {
                                                }
                                            )

                                        }

                                        this.props.form.setFieldsValue({
                                            kanta: singleMachine && singleMachine.kanta
                                        })


                                    }}


                                >

                                    {this.state && this.state.allMachines.map((val, idn) => {
                                        return (
                                            <Option key={idn} value={val.id_ai}>
                                                {val.name}({val.id})
                                            </Option>
                                        )
                                    })}
                                </Select>}
                            </div>
                        </Form.Item>
                        <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeKanta)
