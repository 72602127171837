import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

// inputSchema.fields

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddMachine extends PureComponent {

    state = {
        key: Math.random() * 1000
    }
    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values.id_ai = this.state.id
                    x = await Request.updateMachine(values)
                } else {
                    x = await Request.addMachine(values)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: this.state.id ? 'Machine updated successfully' : 'Machine added successfully'
                    })

                    if (!this.state.id) {
                        this.props.form.setFieldsValue({})
                        form.resetFields()
                    }
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            options: []
        }

    }

    async componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)

        let machineId = searchParams.get('id')

        if (machineId) {
            Request.getMachineById({id_ai: machineId}).then(
                ({data, error, message}) => {
                    if (!error) {

                        this.setState({
                            id: data.id_ai,
                            key: Math.random() & 100
                        })

                        this.props.form.setFieldsValue({
                            id: data && data.id,
                            simNo: data && data.simNo,
                            name: data && data.name,
                            reportsLock: data && data.reportsLock,
                            kanta: data && data.kanta,
                            milkAnalyzer: data && data.milkAnalyzer,
                            password: data && data.password
                        })

                    } else {
                        notification.error({
                            message: 'Error Getting Data',
                            description: message
                        })
                    }
                }
            )
        }
    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, options} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }
        let inputTypes = {
            fields: [
                {
                    label: 'Machine id',
                    key: 'id',
                    required: true
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Sim No/Mobile No',
                    key: 'simNo'
                },
                {
                    label: 'Machine Password',
                    key: 'password',

                },
                {
                    label: 'kanta',
                    key: 'kanta'
                },
                {
                    label: 'reportsLock',
                    key: 'reportsLock'
                },
                {
                    label: 'MilkAnalyzer',
                    key: 'milkAnalyzer',
                    allowClear: true,
                    type: 'select',
                    options: [1, 2, 3, 4],
                    onChange: milkAnalyzer => {
                        setFieldsValue({milkAnalyzer})
                    }
                }
            ]
        }

        return (
            <PageHeaderWrapper title={this.state && this.state.id ?
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('machines.allMachines')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>Update Machine</p></div> : 'Add New Machine'}>

                <Card bordered={true}>
                    <Form
                        key={this.state.key}
                        onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMachine)
