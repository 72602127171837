import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import AllUsers from './containers/users/all'
import AddUsers from './containers/users/add'
import AddMachine from './containers/machines/add'
import AllMachines from './containers/machines/all'
import Map from './containers/machines/map'

import ChangePassword from './containers/DPU/changePassword'
import ChangeKanta from './containers/DPU/changeKanta'
import ChangeMilkAnalyzer from './containers/DPU/changeMilkAnalyzer'

import AddMachineGroup from './containers/machineGroups/add'
import AllMachineGroup from './containers/machineGroups/all'

import AddRateChart from './containers/rateChart/add'
import AllRateCharts from './containers/rateChart/all'
import viewCSV from './containers/rateChart/viewcsv'


import AddFarmer from './containers/farmers/add'
import AllFarmers from './containers/farmers/all'

import AllShifts from './containers/shifts/all'


const menu = [
    {
        'path': '/dashboard',
        'name': 'Dashboard',
        'icon': 'dashboard',
        'key': 'dashboard',
        'homepage': true,
        'component': Dashboard,
        'authority': [
            'admin',
            'manager',
            'groupManager'
        ]
    },
    {
        'path': '/machines',
        'name': 'Machines',
        'icon': 'user',
        'key': 'machines',
        'authority': [
            'admin',
            'manager',
            'groupManager'
        ],
        'children': [
            {
                'path': '/machines/add',
                'name': 'Add Machines',
                'title': 'Add Machines',
                'component': AddMachine,
                'authority': [
                    'admin',
                    'manager']
            },
            {
                'path': '/machines/all',
                'name': 'All Machines',
                'title': 'All Machines',
                'key': 'allMachines',
                'component': AllMachines,
                'authority': [
                    'admin',
                    'manager',
                    'groupManager'
                ]
            },
            {
                'path': '/machine/edit-machine',
                'name': 'Update Machine',
                'title': 'Update Machine',
                'key': 'editMachine',
                dontShowOnMenu: true,
                'component': AddMachine
            },
            {
                'path': '/machines/map',
                'name': 'Map',
                'title': 'Map',
                'component': Map
            }
        ]
    },
    {
        'path': '/machineGroups',
        'name': 'Machine Groups',
        'icon': 'user',
        'key': 'machineGroups',
        'authority': [
            'admin',
            'manager',
            'groupManager'
        ],
        'children': [
            {
                'path': '/machineGroups/add',
                'name': 'Add Machine Groups',
                'title': 'Add Machine Groups',
                'component': AddMachineGroup
            },
            {
                'path': '/machineGroups/all',
                'name': 'All Machine Groups',
                'title': 'All Machine Groups',
                'key': 'machineGroup',
                'component': AllMachineGroup,
                'authority': [
                    'admin',
                    'manager',
                    'groupManager'
                ]
            },
            {
                'path': '/machineGroup/edit-machineGroup',
                'name': 'Update MachineGroup',
                'title': 'Update Machine Group',
                'key': 'editMachineGroup',
                dontShowOnMenu: true,
                'component': AddMachineGroup
            }
        ]
    },
    {
        'path': '/rateChart',
        'name': 'Rate Chart',
        'key': 'rateChart',
        'icon': 'user',
        'children': [
            {
                'path': '/rateChart/add',
                'name': 'Add Rate Chart',
                'title': 'Add RateChart',
                'component': AddRateChart
            },
            {
                'path': '/rateChart/all',
                'name': 'All Rate Charts',
                'title': 'All Rate Charts',
                'key': 'allRateCharts',
                'component': AllRateCharts
            },
            {
                'path': '/rateChart/edit-rateChart',
                'name': 'Update rateChart',
                'title': 'Update rateChart',
                'key': 'editRateChart',
                dontShowOnMenu: true,
                'component': AddRateChart
            },
            {
                'path': '/rateChart/viewCsvFile',
                'name': 'file',
                'title': 'file',
                'key': 'viewcsv',
                'component': viewCSV,
                dontShowOnMenu: true

            }
        ]
    },
    {
        'path': '/shifts',
        'name': 'Shifts',
        'icon': 'user',
        'key': 'shifts',
        'authority': [
            'admin',
            'manager'
        ],
        'children': [
            // {
            //   'path': '/users/add',
            //   'name': 'Add User',
            //   'title': 'Add User',
            //   'component': AddUsers
            // },
            {
                'path': '/shifts/all',
                'name': 'All Shifts',
                'title': 'All Shifts',
                'component': AllShifts
            }
            // {
            //   'path': '/user/edit-user',
            //   'name': 'Update User',
            //   'title': 'Update User',
            //   'key': 'editUser',
            //   dontShowOnMenu: true,
            //   'component': AddUsers
            // }
        ]
    },

    {
        'path': '/dpu',
        'name': 'DPU',
        'key': 'dpu',
        'children': [
            {
                'path': '/DPU/add',
                'name': 'Password Change',
                'title': 'Password Change',
                'component': ChangePassword
            },
            {
                'path': '/DPU/kanta',
                'name': 'Kanta Change',
                'title': 'Kanta Change',
                'component': ChangeKanta
            },
            {
                'path': '/DPU/milkAnalyzer',
                'name': 'Milk Analyzer Change',
                'title': 'MilkAnalyzer Change',
                'component': ChangeMilkAnalyzer
            }
        ]
    },
    {
        'path': '/farmers',
        'name': 'Farmers',
        'icon': 'user',
        'key': 'farmers',
        'authority': [
            'admin',
            'manager',
            'groupManager'
        ],
        'children': [
            {
                'path': '/farmers/add',
                'name': 'Add Farmers',
                'title': 'Add Farmers',
                'component': AddFarmer
            },
            {
                'path': '/farmers/all',
                'name': 'All Farmers',
                'title': 'All Farmers',
                'key': 'allFarmers',
                'component': AllFarmers
            },
            {
                'path': '/Farmer/edit-Farmer',
                'name': 'Update Farmer',
                'title': 'Update Farmer',
                'key': 'editFarmer',
                dontShowOnMenu: true,
                'component': AddFarmer
            }
        ]
    },
    {
        'path': '/users',
        'name': 'Users',
        'icon': 'user',
        'key': 'users',
        'authority': [
            'admin'
        ],
        'children': [
            {
                'path': '/users/add',
                'name': 'Add User',
                'title': 'Add User',
                'component': AddUsers
            },
            {
                'path': '/users/all',
                'name': 'All Users',
                'title': 'All Users',
                'key': 'allUsers',
                'component': AllUsers
            },
            {
                'path': '/user/edit-user',
                'name': 'Update User',
                'title': 'Update User',
                'key': 'editUser',
                dontShowOnMenu: true,
                'component': AddUsers
            }
        ]
    }


]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = (keyString) => {

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu

