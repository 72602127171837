import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import moment from 'moment'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllMachines extends Component {

    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await Request.getAllMachines(params)
            resolve(data)
        })
    }
    deleteMachine = async data => {
        this.setState({loading: true})
        let x = await Request.deleteMachine(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data.id_ai}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data.id_ai}-close`
            })
        }


    }

    constructor(props) {
        super(props)
        this.table = React.createRef()

    }

    componentDidMount() {
    }

    render() {
        const {dispatch} = this.props

        const columns = [
            {

                title: 'Machine id',
                key: 'id',
                sorter: true,
                dataIndex: 'id',
                searchTextName: 'id'

            },
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
                searchTextName: 'name'
            },

            {
                title: 'Sim No/Mobile No',
                key: 'simNo',
                sorter: true,
                dataIndex: 'simNo',
                searchTextName: 'simNo'

            },
            {
                title: 'Machine Password',
                key: 'password',
                sorter: true,
                dataIndex: 'password',
                searchTextName: 'password'
            },
            {

                title: 'kanta',
                key: 'kanta',
                sorter: true,
                dataIndex: 'kanta',
                searchTextName: 'kanta'
            },
            {
                title: 'Reports Lock',
                key: 'reportsLock',
                sorter: true,
                dataIndex: 'reportsLock',
                searchTextName: 'reportsLock'
            },
            {
                title: 'Milk Analyzer',
                key: 'milkAnalyzer',
                sorter: true,
                dataIndex: 'milkAnalyzer',
                searchTextName: 'milkAnalyzer'
            },
            {
                title: 'Machine Groups Id',
                key: 'groups',
                dataIndex: 'groups',
                render: (val, record) => {
                    return <div>{record.groups && record.groups.map((u) => {
                        return <ul>
                            <li>{u}</li>
                        </ul>
                    })}</div>
                }
            },
            {
                key: 'actions',
                title: 'Actions', fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return <div id={'actions'}>
                        <Tooltip title="Edit Machine">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('machines.editMachine', {
                                            id: record.id_ai
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete Machine">
                            <Popconfirm
                                title="Are you sure delete this Machine?"
                                onConfirm={() => {
                                    this.deleteMachine({id_ai: record.id_ai})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>


                    </div>

                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={'All Machines'}>

                <Card bordered={true}>
                    <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest}/>
                </Card>

            </PageHeaderWrapper>)

    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllMachines)
