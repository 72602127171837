import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Checkbox,
    Button,
    Card,
    Row,
    Col,
    Spin,
    Empty,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import styles from './styles.less'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

// inputSchema.fields

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddMachineGroup extends PureComponent {

    state = {
        key: Math.random() * 1000,
        allMachines: null,
        initialSelectedIds: null
    }
    allMachines = async () => {

        this.setState({allMachinesLoading: true})

        let allmachines = await Request.allMachines()
        this.setState({allMachinesLoading: false, allMachines: allmachines.data})

    }
    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values.id_ai = this.state.id
                    x = await Request.updateMachineGroup(values)
                } else {
                    x = await Request.addMachineGroup(values)
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id ? 'MachineGroup updated successfully' : 'MachineGroup added successfully'
                    })

                    if (!this.state.id) {
                        this.props.form.setFieldsValue({})
                        form.resetFields()
                    }
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            options: []
        }

    }

    async componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)

        let machineGroupId = searchParams.get('id')
        if (machineGroupId) {

            this.allMachines()

            Request.getMachineGroupById({id_ai: machineGroupId}).then(
                ({data, error, message}) => {
                    if (!error) {
                        this.setState({
                            id: data.id_ai,
                            key: Math.random() & 100
                        })
                        this.props.form.setFieldsValue({
                            name: data.name,
                            machineIds: data.machineIds && data.machineIds.map(x => parseInt(x))
                        })


                    } else {
                        notification.error({
                            message: 'Error Getting Data',
                            description: message
                        })
                    }
                }
            )
        } else {
            this.allMachines()
        }


    }

    render() {

        const {form: {getFieldDecorator}, dispatch} = this.props
        const {allMachines, allMachinesLoading} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        return (
            <PageHeaderWrapper title={this.state && this.state.id ?
                <div style={{display: 'flex'}}>
                    <Button style={{marginRight: '20px'}}
                            onClick={() => {
                                dispatch(
                                    getUrlPushWrapper('machineGroups.machineGroup')
                                )
                            }}>
                        <Icon type="left"/>
                    </Button><p>Update Machine Group</p></div> : 'Add New Machine Group'}>

                <Card bordered={true}>

                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                        <Form.Item label="Group Name">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input machine group name'
                                    }
                                ]
                            })(<Input/>)}
                        </Form.Item>


                        {
                            /*<Checkbox.Group className={styles.checkbox}>
                                                                {!allMachinesLoading ? <Row>
                                                                    {allMachines ? allMachines.map((val, key) => {
                                                                        return (<Col className={styles.column} key={val.id_ai} span={24}>
                                                                            <Checkbox value={val.id_ai}>{val.name} ({val.id_ai})</Checkbox>
                                                                        </Col>)
                                                                    }) : <Empty/>}
                                                                </Row> : <Spin/>}
                                                            </Checkbox.Group>*/
                        }


                        <Form.Item label="Machines in Group">
                            {getFieldDecorator('machineIds', {})(
                                <Checkbox.Group className={styles.checkbox}
                                                options={(() => {
                                                    return allMachines ? allMachines.map(x => ({
                                                        label: `${x.name} (${x.id})`,
                                                        value: x.id_ai
                                                    })) : []
                                                })()}
                                />
                            )}
                        </Form.Item>


                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>


                    </Form>


                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMachineGroup)
